.title {
  background-color: rgb(24, 24, 24);
  box-shadow: 0 0 10px 10px rgb(70, 70, 70);
}

h1,
.resultText {
  color: rgb(220, 220, 220);
}

.resultAttribute {
  color: #a73335;
}

.card-header .fa {
  transition: 0.3s transform ease-in-out;
}
.card-header .collapsed .fa {
  transform: rotate(-90deg);
}

.fa2 {
  transition: 0.3s transform ease-in-out;
}
.collapsed .fa2 {
  transform: rotate(-90deg);
}

.modifier-heading {
  background-color: rgb(44, 48, 52) !important;
}

.modifier-body {
  background-color: rgb(50, 54, 58) !important;
}

.sectionText {
  position: relative;
  top: 4px;
}

hr {
  border: none;
  border-top: 3px double red;
  color: red;
  overflow: visible;
  text-align: center;
  height: 5px;
}

.npc-list {
  cursor: pointer;
}

@media only screen and (max-width: 550px) {
  .statBlock {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .npc-list {
    font-size: 80%;
  }
}

.npc-list-border {
  border-color: #a73335 !important;
}

.npc-list-active {
  background-color: rgb(50, 54, 58) !important;
}

.lock {
  cursor: pointer;
}

.lock-transparent {
  color: transparent;
}
