.block {
  width: 500px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  background-color: rgb(236, 232, 223);
  margin: auto;
}
.gradient {
  background: linear-gradient(10deg, #a73335, white);
  height: 5px;
  margin: 7px 0px;
}
.name {
  font-size: 225%;
  font-family: Georgia, serif;
  font-variant: small-caps;
  font-weight: bold;
  color: #a73335;
}
.description {
  font-style: italic;
}
.bold {
  font-weight: bold;
}
.red {
  color: #a73335;
}
.tableBlock {
  width: 100%;
  border: 0px;
  border-collapse: collapse;
  color: #a73335;
}
.thBlock,
.tdBlock {
  width: 50px;
  text-align: center;
}
.actions {
  font-size: 175%;
  font-variant: small-caps;
  margin: 17px 0px 0px 0px;
}
.hr {
  background: #a73335;
  height: 2px;
}
.attack {
  margin: 5px 0px;
}
.attackname {
  font-weight: bold;
  font-style: italic;
}
